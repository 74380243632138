import { api } from '../connection/api'

export async function getAllInvoiceSerie(data) {
    return await api.get(`invoice/invoice_serie`,{ params: data});
}

export async function getInvoiceSerie(data) {
    return await api.get(`invoice/invoice_serie/${data.id}`);
}

export async function putChangeStatusInvoiceSerie(data) {
    return await api.put(`invoice/invoice_serie/change_status/${data.id}`);
}

export async function putInvoiceSerie(data) {
    return api.put(`invoice/invoice_serie/update/${data.id}`,data)
}

export async function postInvoiceSerie(data) {
    return api.post(`invoice/invoice_serie/create`,data)
}

export async function postProductInvoiceSerie(data) {
    return api.post(`invoice/invoice_serie/product/create`, data)
}

export async function listProductInvoiceSerie(data) {
    return api.get(`invoice/invoice_serie/product/list`, { params: data})
}

export async function getProductInvoiceSerie(id) {
    return api.get(`invoice/invoice_serie/product/${id}`)
}

export async function updateProductInvoiceSerie(data) {
    return api.put(`invoice/invoice_serie/product/${data.id}`, data)
}

export async function updateStatusProductInvoiceSerie(id) {
    return api.put(`invoice/invoice_serie/product/active/${id}`)
}
export async function atualizaBasketServico(guid) {
    return api.get(`/invoice/invoice_create/atualizaBasketServico/${guid}`)
}