import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PageDefault from "../../components/pageDefault";
import { Link } from "react-router-dom";
import Empresa from "../../imgs/icons/dadosEmpresa.svg";
import CertificadoMEI from "../../imgs/icons/certificadoMEI.svg";
import logoACSP from "../../imgs/icons/logoACSP.svg";
import Chart from "../../components/chartData";
import iconsIMG from "../../components/ImgImport/index";
import DASCard from "../../components/DASInfo/DASCard/index";
import DASNCard from "../../components/DASInfo/DASNCard/index";
import AgendeConsultoriaCard from "../../components/DASInfo/agendeConsultoriaCard/index";
import TrainingCard from "../../components/trainingCards/index";
import { getCertificate } from "../../services/associate/index";
import { getAllDas, downloadDasByAssociated } from "../../services/das/index";
import { fetchDasn } from "../../services/dasn/index";
import { fetch } from "../../services/training/index";
import jwt_decode from "jwt-decode";
import "./App.css";
import {
  getInvoiceChartData,
  getInvoiceYears,
} from "../../services/invoice/nfe-complements";
import ParcelsCard from "../../components/ParcelsCard";
import { getBillToPayParcelsByDate } from "../../services/billsToPay";
import { getBillToReceiveParcelsByDate } from "../../services/billsToReceive";
import MapaDistritais from "../../components/MapaDistritais/index";
import { Box } from "@mui/system";
import { amountNfsIssued } from "../../services/associate/nfe";
import { SubscriptionPlan } from "../../enumerations/Associated/subscriptionPlan";
import ModalWarningUpdate from "../../components/global/ModalWarningUpdate";

function Home() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const AlreadyShown = sessionStorage.getItem("WarningUpdateShown");
    if (!AlreadyShown) {
      setOpen(true);
      sessionStorage.setItem("WarningUpdateShown", true);
    }
  }, []);

  const [associated, setAssociated] = useState({});
  const [dases, setDases] = useState([]);
  const [dasns, setDasns] = useState([]);
  const [certificateURL, setCertificateURL] = useState("");
  const [trainings, setTrainings] = useState("");

  const [years, setYears] = useState([]);
  const [chartData, setChartData] = useState({});
  const [year, setYear] = useState(null);

  const [billToPayParcels, setBillToPayParcels] = useState([]);
  const [billToReceiveParcels, setBillToReceiveParcels] = useState([]);

  const [amountNfIssuedReached, setAmountNfIssuedReached] = useState(0);
  const [subscriptionPlanFREE, setSubscriptionPlanFREE] = useState(false);

  useEffect(() => {

    const token = localStorage.getItem("jwt_auth");
    const decodedToken = jwt_decode(token);
    setAssociated(decodedToken);
    const { planoAssinatura } = jwt_decode(token);


    // console.log('associated', decodedToken);
    // console.log('planoAssinatura', planoAssinatura);


    amountNfsIssued()
      .then(({ data }) => {
        if (planoAssinatura !== null) {
          if (planoAssinatura.id === SubscriptionPlan.FREE) {
            setAmountNfIssuedReached(data.amount);
            setSubscriptionPlanFREE(true);
          }
        }
      })
      .catch((error) => console.log(error));

    getAllDas({
      year: new Date().getFullYear(),
      fieldOrder: "month",
      perPage: 12,
      order: "ASC",
    }).then((response) => {
      let { results } = response.data;
      const currentYear = new Date().getFullYear();
      if (results.length == 0) {
        setDases([]);
      }
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // +1 porque os meses em JavaScript são baseados em zero
      // const currentMonth = 8; // +1 porque os meses em JavaScript são baseados em zero

      const monthNames = {
        Janeiro: 1,
        Fevereiro: 2,
        Março: 3,
        Abril: 4,
        Maio: 5,
        Junho: 6,
        Julho: 7,
        Agosto: 8,
        Setembro: 9,
        Outubro: 10,
        Novembro: 11,
        Dezembro: 12,
      };
      // Filtra os itens do array para remover todos os meses após o mês atual
      let filteredData = results.filter((item) => {
        if (item.year < currentYear) {
          return true; // Mantém os meses de anos anteriores
        } else if (
          item.year === currentYear &&
          monthNames[item.month] <= currentMonth
        ) {
          return true; // Mantém os meses do ano atual que são iguais ou anteriores ao mês atual
        } else {
          return false; // Remove os meses após o mês atual
        }
      });

      if (filteredData.length > 3) {
        filteredData = filteredData.slice(-3);
        setDases(filteredData);
      } else {
        setDases(filteredData);
      }
    });

    // getCertificate().then((response) => {
    //   setCertificateURL(response.data.certificate);
    // });

    fetchDasn().then((response) => {
      // if(response.length == 0)
      const { data } = response;
      const dadosFiltrados = data.filter((item) => item.status !== "UNKNOWN");
      console.log(dadosFiltrados);
      if (dadosFiltrados.length <= 3) {
        setDasns(dadosFiltrados);
      } else {
        setDasns(
          dadosFiltrados.slice(dadosFiltrados.length - 3, dadosFiltrados.length)
        );
      }
    });

    fetch().then((response) => {
      const { results } = response.data;
      setTrainings(results.slice(results.length - 3, results.length));
    });
  }, []);

  useEffect(() => {
    const today = new Date();
    getBillToPayParcelsByDate(today)
      .then((res) => {
        setBillToPayParcels(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getBillToReceiveParcelsByDate(today)
      .then((res) => {
        setBillToReceiveParcels(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (year !== null) {
      getInvoiceChartData(year)
        .then((res) => {
          setChartData({
            ...chartData,
            services: res.data.services,
            products: res.data.products,
            total: res.data.total,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [year]);

  const page = (
    <div className="container">
      <div className="section">
        {false && (
          <div className="row">
            <div class="alert alert-danger" role="alert">
              MEI que não regularizar situação pode ficar sem CNPJ.
              <a
                href="http://www8.receita.fazenda.gov.br/SimplesNacional/Servicos/Grupo.aspx?grp=19"
                target="_blank"
              >
                {" "}
                Clique aqui
              </a>{" "}
              e regularize sua situação junto a Receita Federal para evitar que
              suas dívidas sejam cobradas na justiça, o MEI precisa quitar ou
              parcelar seus débitos até 30 de Setembro. Caso tenha dúvidas de
              como parcelar suas dívidas,{" "}
              <a
                href="/docs/Manual_regularização_receita_federal_mei.pdf"
                target="_blank"
              >
                clique aqui
              </a>
              .
            </div>
          </div>
        )}
        {/* <div className="row">
          <div className="info col">
            <Button onClick={handleClickOpen} variant="contained"  sx={{backgroundColor:"#D70F57",fontColor:"#FFF",     "&:hover": {
      backgroundColor: "#D70F57"
    }}} endIcon={<ErrorOutlineIcon />}>Procedimento Importante </Button>
            <p className="reception">Seja bem-vindo!</p>
            <h3>{associated.nomeFantasia}</h3>
            <p>
              Essa é o portal ideal para você, empreendedor e parceiro da
              Associação Comercial de São Paulo!
            </p>

            <Link to="/profile" className="button">
              <div className="flex">
                <img src={Empresa} alt="" />
                <p>Dados da empresa</p>
              </div>
              <i className="bi bi-chevron-right" />
            </Link>
            <Link
              target="_blank"
              to={{ pathname: certificateURL }}
              className="button"
            >
              <div className="flex">
                <img src={CertificadoMEI} alt="" />
                <p>Emitir certificado de MEI</p>
              </div>
              <i className="bi bi-chevron-right" />
            </Link>
          </div>
          <div className="col">
            <div className="card">
              <h4> Agende sua consultoria </h4>
              <p>
                Os Consultores da Associação Comercial de São Paulo estão
                prontos para te ajudar!
              </p>
              <Link className="linkCard" to="/consultancy">
                <p>Saiba mais</p>
              </Link>
              <img src={logoACSP} alt="Logo"></img>
            </div>
          </div>
        </div> */}
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginBottom: "40px",
            }}
          >
            {/* <Box
              sx={{
                width: "150px",
                height: "45px",
                bgcolor: "#58C5C6",
                color: "#fff",
                borderRadius: "7px",
                marginRight: "15px",
                textAlign: "center",
                paddingBottom: "1%",
                paddingTop: "1%",
              }}
            >
              0/1 DAS
            </Box> */}
            {subscriptionPlanFREE && (
              <Box
                sx={{
                  width: "150px",
                  height: "45px",
                  bgcolor: "#58C5C6",
                  color: "#fff",
                  borderRadius: "7px",
                  marginRight: "15px",
                  textAlign: "center",
                  paddingBottom: "1%",
                  paddingTop: "1%",
                }}
              >
                {amountNfIssuedReached}/5 Notas fiscais
              </Box>
            )}
          </Box>
          {/*
          <Box
            sx={{
              width: "100%",
              height: "250px",
              bgcolor: "#58C5C6",
              borderRadius: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <h1>
              <b>SEMANA DO MICROEMPREENDEDOR INDIVIDUAL</b>
            </h1>
          </Box>
          */}
        </Box>
      </div>
      <div className="section">
        <div>
          <div className="row">
            <div className="col-5">
              <DASCard icon={iconsIMG.DAS} data={dases} />
            </div>
            <div className="col-4">
              <DASNCard icon={iconsIMG.DASN} data={dasns} />
            </div>
            <div className="col-3">
              <AgendeConsultoriaCard />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div>
          <div className="row">
            <div className="col">
              <ParcelsCard
                title="Contas a Pagar"
                noResultsMsg="Sem contas a pagar hoje"
                cardLinkTo="/financial/bills_to_pay"
                itemLinkTo="/financial/bills_to_pay/view"
                data={billToPayParcels}
                icon={iconsIMG.BillPay}
              />
            </div>
            <div className="col">
              <ParcelsCard
                title="Contas a Receber"
                noResultsMsg="Sem contas a receber hoje"
                cardLinkTo="/financial/bills_to_receive"
                itemLinkTo="/financial/bills_to_receive/view"
                data={billToReceiveParcels}
                icon={iconsIMG.BillReciver}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="card">
          <div className="titleChart">
            <h5>Notas</h5>
            <div>
              <select
                name="year"
                className="custom-select"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {years?.map((e) => {
                  return <option value={e.year}> {e.year} </option>;
                })}
              </select>
            </div>
          </div>
          <Chart data={chartData} />
        </div>
      </div>

      {/* <div className="section">
        <TrainingCard data={trainings} />
      </div> */}

      <div className="section" style={{ marginTop: "30px" }}>
        <div>
          <MapaDistritais data={trainings} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
      <ModalWarningUpdate handleClose={handleClose} open={open} />
    </>
  );
}

export default Home;
