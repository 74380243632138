import React from "react";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { Box, Typography } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormLabel } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import "./PlanStatus.css";
import { Button } from "@mui/material";
import Ativo from "../../../imgs/icons/planoPagamento/Ativo.svg";
import Suspenso from "../../../imgs/icons/planoPagamento/Suspenso.svg";
import { useEffect, useState } from "react";
import { AssociatedType } from "../../../enumerations/Associated/associatedType";
import { SubscriptionPlanType } from "../../../enumerations/Associated/subscriptionPlanType";
import { PaymentForm } from "../../../enumerations/OrderSale/paymentForm";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import {
  findBySubscriptionPlanType,
  findBySubscriptionPlanAssociate,
} from "../../../services/subscriptionPlanService";
import { Document, Page, pdfjs } from "react-pdf";
import {
  sendOrderSale,
  removeOrderSale,
  findByTransaction,
  findByAssociatedId,
} from "../../../services/orderSaleService";
import {
  checkRecurrenceActive,
  cancelRecurrence,
} from "../../../services/recurrence";
import { useFormik } from "formik";
import { useField } from "formik";

import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./fonteSourceSansPro.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import adeptTerms from "../../../docs/adeptterm.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

export default function PlanStatus() {
  const [active, setActive] = useState(true);
  const [associatedId, setAssociatedId] = useState(undefined);
  const [customerCode, setCustomerCode] = useState(undefined);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(undefined);
  const [subscriptionPlanType, setSubscriptionPlanType] = useState(undefined);
  const [initialDate, setInitialDate] = useState(undefined);
  const [expirationDate, setExpirationDate] = useState(undefined);
  const [subscriptionPlanData, setSubscriptionPlanData] = useState([]);
  const [valuePlan, setValuePlan] = useState(undefined);
  const [paymentLink, setPaymentLink] = useState(undefined);
  const [paid, setPaid] = useState(false);
  const [showPlans, setShowPlans] = useState(true);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [recurrenceActive, setRecurrenceActive] = useState(false);
  const [orderPlan, setOrderPlan] = useState("");
  const [expirandoPlano, setExpirandoPlano] = useState(false);
  const [numPages, setNumPages] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openModalTerms, setOpenModalTerms] = React.useState(false);
  const [avisoExpiracao, setAvisoExpiracao] = useState("");
  const [nomePlanoAssinatura, setNomePlanoAssinatura] = useState();
  const handleClose = () => setOpen(false);
  const handleCloseModalTerms = () => setOpenModalTerms(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [cartaoRecorrencia, setCartaoRecorrencia] = useState(false);
  const [alteracaoCartao, setAlteracaoCartao] = useState(false);
  const [awaitPayment, setAwaitPayment] = useState(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    setLoadingBackdrop(true);
    const token = localStorage.getItem("jwt_auth");

    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    const { id, associatedCode, associatedTypeId, planoAssinatura } = jwt_decode(token);

    setAssociatedId(id);
    setCustomerCode(associatedCode);
    setNomePlanoAssinatura(planoAssinatura ? planoAssinatura.nome : "");
    localStorage.setItem("classeAssociado", associatedTypeId);

    const subscriptionPlanTypeId =
      associatedTypeId === AssociatedType.PARTICIPANT
        ? SubscriptionPlanType.PARTICIPANT
        : SubscriptionPlanType.CONTRIBUTOR;

    if(planoAssinatura) {    
      if (currentDate > planoAssinatura.dataTerminoVigencia ||  planoAssinatura.subscriptionPlanTypeId === SubscriptionPlanType.FREE) {
        if (currentDate > planoAssinatura.dataTerminoVigencia) {
          setActive(false);
          setShowPlans(true);
        }
        //getSubscriptionPlanType(subscriptionPlanTypeId);
      }

      setSubscriptionPlanType(planoAssinatura.subscriptionPlanTypeId);
      setInitialDate(planoAssinatura.dataInicioVigencia);
      setExpirationDate(planoAssinatura.dataTerminoVigencia);

      // console.log(planoAssinatura.subscriptionPlanTypeId);
      if (planoAssinatura.subscriptionPlanTypeId === SubscriptionPlanType.FREE) {
        setShowPlans(true);
      }
    }
    getOrderSalesByAssociatedId(id);
    getSubscriptionPlanAssociate(id);

  }, []);

  function calculaDiferencaDias(currentDate, expirationDate) {
    const diferencaEmMilissegundos =
      new Date(expirationDate) - new Date(currentDate);
    const diferencaEmDias = Math.ceil(
      diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
    );
    return diferencaEmDias;
  }
  async function getSubscriptionPlanAssociate(id) {
    await findBySubscriptionPlanAssociate(id).then(({ data }) => {
      // console.log('datagetSubscriptionPlanAssociate:',data);
      if(!data) {
        setShowPlans(true);
        setRecurrenceActive(false);
        setActive(false);
        getSubscriptionPlanType(localStorage.getItem("classeAssociado"));
        return;
      }
      console.log("OK");
      if(data.subscriptionPlanId == 1) {
        getSubscriptionPlanType(localStorage.getItem("classeAssociado"));
        setShowPlans(true);
        return;
      }
      const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
      if (!recurrenceActive) {
        getSubscriptionPlanType(localStorage.getItem("classeAssociado"));
        if (currentDate > data.expirationDate) {
          setActive(false);
          setShowPlans(true);
        } else {
          setActive(true);
          setSubscriptionPlanType(data.subscriptionPlanId);
          const diffDias = calculaDiferencaDias(
            currentDate,
            data.expirationDate
          );

          if (diffDias < 5) {
            setShowPlans(true);
            setAvisoExpiracao("Seu plano está vencendo, faça a ativação");
          } else {
            setShowPlans(false);
          }

          setExpirationDate(data.expirationDate);
        }
      } else {
        setShowPlans(false);
      }
    });
  }

  async function getSubscriptionPlanType(subscriptionPlanTypeId) {
    let classe = subscriptionPlanTypeId == 1 ? 2 : 3;
    findBySubscriptionPlanType(classe).then(({ data }) => {
      setSubscriptionPlanData(data);
    });
  }

  function openModalTermsPop(e) {
    setOpenModalTerms(true);
  }
  async function getOrderSalesByAssociatedId(associatedId) {
    console.log("id associado " + associatedId);
    await findByAssociatedId(associatedId).then(({ data }) => {
      if(!data) {
        setLoadingBackdrop(false);
        return;
      }
      setPaymentLink(data.paymentLink);
      setPaid(data.paid);

      if (data.subscriptionPlans) {
        setOrderPlan(data.subscriptionPlans.description);
      }
      if (data.recorrencia) {
        //condicao para recorrencia
        if (data.recorrenciaInvoiceStatus != null) {
          if (data.recorrenciaInvoiceStatus == "paid") {
            setRecurrenceActive(true);
            setShowPlans(false);
          } else {
            setRecurrenceActive(false);
            setShowPlans(true);
            setAlteracaoCartao(true);
            toast.error(
              `Ocorreu um erro na ultima cobrança da fatura ${data.recorrenciaResposta}`,
              {
                autoClose: false,
              }
            );
          }
        } else {
          setRecurrenceActive(true);
          setShowPlans(false);
        }
      }
      setLoadingBackdrop(false);
    });
  }

  // async function checkExistRecurrenceActive(associatedId) {
  //   await checkRecurrenceActive(associatedId).then(({ data }) => {
  //     setRecurrenceActive(data);
  //   });
  // }

  function delay(t) {
    return new Promise((resolve) => {
      setTimeout(resolve, t);
    });
  }

  async function tryFetchTransaction(
    associatedId,
    currentTry = 0,
    maxRetries = 3
  ) {
    await delay(10000);
    let id = associatedId;
    await findBySubscriptionPlanAssociate(id)
      .then(async (data) => {
        const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
        if (currentDate > data.data.expirationDate) {
          if (currentTry < maxRetries) {
            await tryFetchTransaction(associatedId, currentTry + 1, maxRetries);
          }
        } else {
          setActive(true);
          setShowPlans(false);
          setSubscriptionPlanType(data.data.subscriptionPlanId);
          setExpirationDate(data.data.expirationDate);
        }
      })
      .catch(async (err) => {
        err.response.data.errors.forEach((error) => {
          console.log(error);
        });
        toast.error(
          "Sistema indisponível no momento. Por favor tente mais tarde"
        );
      });
  }

  const handleCancelRecurrence = async () => {
    setLoadingBackdrop(true);
    await cancelRecurrence(associatedId)
      .then(async (res) => {
        toast.success("Recorrência cancelada com sucesso");
        handleClose();
        setShowPlans(false);
        setRecurrenceActive(false);
        setAlteracaoCartao(false);
        setShowCardForm(false);
        setAvisoExpiracao("");
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.errors.forEach((error) => {
            console.log(error);
          });
          toast.error(
            "Erro ao cancelar a recorrência. Por favor tente mais tarde"
          );
          handleClose();
        }
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };
  const validateCardNumber = (num) => {
    if (!num) return false;
    var msg = Array();
    var tipo = null;
    num = num.replace(/\s/g, "");
    if (num.length == 15) return true;
    if (num.length > 16 || num[0] == 0) {
      return false;
    } else {
      var total = 0;
      var arr = Array();

      for (let i = 0; i < num.length; i++) {
        if (i % 2 == 0) {
          let dig = num[i] * 2;

          if (dig > 9) {
            let dig1 = dig.toString().substr(0, 1);
            let dig2 = dig.toString().substr(1, 1);
            arr[i] = parseInt(dig1) + parseInt(dig2);
          } else {
            arr[i] = parseInt(dig);
          }

          total += parseInt(arr[i]);
        } else {
          arr[i] = parseInt(num[i]);
          total += parseInt(arr[i]);
        }
      }
      let operadora = "";
      switch (parseInt(num[0])) {
        case 0:
          msg.push("Número incorreto");
          break;
        case 1:
          tipo = "Empresas Aéreas";
          break;
        case 2:
          tipo = "Empresas Aéreas";
          break;
        case 3:
          tipo = "Viagens e Entretenimento";
          if (
            parseInt(num[0] + num[1]) == 34 ||
            parseInt(num[0] + num[1]) == 37
          ) {
            operadora = "Amex";
          }
          if (parseInt(num[0] + num[1]) == 36) {
            operadora = "Diners";
          }
          break;
        case 4:
          tipo = "Bancos e Instituições Financeiras";
          operadora = "visa";
          break;
        case 5:
          if (
            parseInt(num[0] + num[1]) >= 51 &&
            parseInt(num[0] + num[1]) <= 55
          ) {
            operadora = "Mastercard";
          }
          tipo = "Bancos e Instituições Financeiras";
          operadora = "Mastercard";
          break;
        case 6:
          tipo = "Bancos e Comerciais";
          operadora = "";
          break;
        case 7:
          tipo = "Companhias de petróleo";
          operadora = "";
          break;
        case 8:
          tipo = "Companhia de telecomunicações";
          operadora = "";
          break;
        case 9:
          tipo = "Nacionais";
          operadora = "";
          break;
        default:
          msg.push("Número incorreto");
          break;
      }
    }

    if (msg.length > 0) {
      console.log(msg);
      if (msg == "Número incorreto") return false;
    } else {
      console.log(num);

      if (total % 10 == 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  const validateCardName = (value) => {
    if (!value) {
      return "Nome do cliente é obrigatório";
    }

    // Verificar se há mais de uma palavra
    if (value.trim().split(" ").length !== 1) {
      return "Por favor, insira apenas o primeiro nome";
    }

    return undefined; // Retorna undefined se a validação passar
  };
  const validDate = (value) => {

    if (!value || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
      return false; // Verifica se está no formato MM/YY
    }
  
    const [month, year] = value.split("/").map(Number);
    const now = new Date();
    const currentMonth = now.getMonth() + 1; // Mês atual (0-indexado, por isso +1)
    const currentYear = now.getFullYear() % 100; // Últimos dois dígitos do ano
  
    if (year < currentYear) {
      return false; // O ano é inferior ao ano atual
    }

    if (year === currentYear && month < currentMonth) {
      return false; // O mês é inferior ao mês atual no mesmo ano
    }  
    return true; // Data válida

  };
  const formik = useFormik({
    initialValues: {
      radioPlans: "",
      createAcceptTerms: false,
      cardNumber: "",
      cardName: "",
      expiryDate: "",
      cvv: "",
    },
    onChange: (e) => {
      formik.setFieldValue("cardNumber", "");
      // setCardNumber("");
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:
      cartaoRecorrencia || alteracaoCartao
        ? Yup.object().shape({
            cardNumber: Yup.string()
              .required("Número do cartão é obrigatório")
              .test("cardNumber", "Número do cartão inválido", (value) =>
                validateCardNumber(value)
              ),
            cardName: Yup.string()
              .required("Nome do cliente é obrigatório")
              .test(
                "check-first-name",
                "Por favor, insira apenas o primeiro nome",
                (value) => validateCardName(value)
              ),

            expiryDate: Yup.string()
              .required("Data de validade é obrigatória")
              .test("check-valid-date", "Data de validade inválida", (value) => {
                // console.log(value);
                return validDate(value);
                }),
            cvv: Yup.string()
              .required("CVV é obrigatório")
              .matches(/^\d{3,4}$/, "CVV inválido"),
          })
        : undefined,
    onSubmit: async (values) => {
      console.log(cartaoRecorrencia);
      if (!values.createAcceptTerms) {
        toast.error("É necessário aceitar os termos para prosseguir.");
        return;
      }
      setLoadingBackdrop(true);
      let orderSaleDTO = {};
      if (alteracaoCartao) {
        orderSaleDTO = {
          alteracartao: true,
          customerCode,
          creditCard: {
            cardName: values.cardName,
            cardNumber: values.cardNumber,
            cvv: values.cvv,
            expiryDate: values.expiryDate,
          },
        };
      } else if (cartaoRecorrencia) {
        orderSaleDTO = {
          customerCode,
          subscriptionPlanId,
          messageForNote: `Compra via ${PaymentForm.CREDIT_CARD}`,
          formPayment: PaymentForm.CREDIT_CARD,
          creditCard: {
            cardName: values.cardName,
            cardNumber: values.cardNumber,
            cvv: values.cvv,
            expiryDate: values.expiryDate,
          },
          products: [
            {
              productId: values.radioPlans,
              value: valuePlan,
            },
          ],
        };
      } else {
        orderSaleDTO = {
          customerCode,
          subscriptionPlanId,
          messageForNote: `Compra via ${PaymentForm.CREDIT_CARD}`,
          formPayment: PaymentForm.CREDIT_CARD,
          products: [
            {
              productId: values.radioPlans,
              value: valuePlan,
            },
          ],
        };
      }
      console.log(orderSaleDTO);

      await sendOrderSale(orderSaleDTO)
        .then(async (response) => {
          console.log(response);
          if (cartaoRecorrencia) {
            setRecurrenceActive(true);
            setShowPlans(false);
          } else if (alteracaoCartao) {
            setRecurrenceActive(true);
            setShowPlans(false);
          } else {
            window.open(response.data.checkouts[0].payment_url, "_blank");
            setPaymentLink(response.data.checkouts[0].payment_url);
            setAwaitPayment(true);
            await tryFetchTransaction(associatedId);
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message);
          }
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    },
  });

  return (
    <>
      <Dialog
        open={openModalTerms}
        onClose={handleCloseModalTerms}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 25, width: 800 },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1400px", // Set your width here
            },
          },
        }}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ height: 50 }}>
          {handleCloseModalTerms ? (
            <IconButton
              aria-label="close"
              onClick={handleCloseModalTerms}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[600],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <Document
            file={adeptTerms}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={console.error}
            options={{ workerSrc: "pdf.worker.js" }}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  scale={1.25}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  customTextRenderer={false}
                  pageNumber={page}
                />
              ))}
          </Document>
          {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
        </DialogContent>
        <DialogContentText sx={{ height: 150 }} align="center">
          <Button
            sx={{
              marginTop: 1,
              marginBottom: 2,
              bgcolor: "#58C5C6",
              color: "#fff",
              textTransform: "none !important",
              width: "25%",
              fontFamily: "Source Sans Pro",
              "&:hover": {
                bgColor: "#fff",
                color: "#58C5C6",
                border: "1px solid #58C5C6",
              },
            }}
            onClick={handleCloseModalTerms}
          >
            OK
          </Button>
        </DialogContentText>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 25 },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            align="center"
            sx={{
              fontWeight: "bold",
              color: "#ed2d53",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            Cancelamento
          </Typography>
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[600],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="center">
            Ao cancelar a sua assinatura recorrente, você <br /> descontinuará
            tudo que construiu com a gente até aqui. <br />
            Para voltar a utilizar o portal, você precisará efetuar
            <br /> o pagamento novamente.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            align="center"
            sx={{ marginTop: 3 }}
          >
            Deseja cancelar o pagamento recorrente?
          </DialogContentText>
        </DialogContent>
        <DialogContentText sx={{ height: 75 }} align="center">
          <Button
            sx={{
              bgcolor: "#7B7B7B",
              color: "#fff",
              textTransform: "none !important",
              width: "25%",
              fontFamily: "Source Sans Pro",
              "&:hover": {
                bgColor: "#fff",
                color: "#7B7B7B",
                border: "1px solid rgba(135, 135, 135, 1)",
              },
            }}
            onClick={handleClose}
          >
            NÃO
          </Button>
          <Button
            sx={{
              marginLeft: "20px",
              bgcolor: "#ed2d53",
              color: "#fff",
              textTransform: "none !important",
              width: "25%",
              fontFamily: "Source Sans Pro",
              "&:hover": {
                bgColor: "#fff",
                color: "#ed2d53",
                border: "1px solid #ed2d53",
              },
            }}
            onClick={handleCancelRecurrence}
            autoFocus
          >
            SIM
          </Button>
        </DialogContentText>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingBackdrop}
        >
          <CircularProgress color="inherit" />{" "}
        </Backdrop>
      </Dialog>

      <Box>
        <Grid container spacing={0} columns={16} width="381px">
          <Grid item xs={16}>
            <Box
              sx={{
                fontSize: "32px",
                marginBottom: "30px",
                fontFamily: "Source Sans Pro",
                color: "#7B7B7B",
              }}
            >
              Plano/Pagamento
            </Box>
          </Grid>
          <Grid item xs={12}>
            <p
              style={{
                marginBottom: "0",
                fontFamily: "Source Sans Pro",
                fontSize: "18px",
                color: "#7B7B7B",
              }}
            >
              Status do plano:
            </p>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                height: "50px",
                alignItems: "center",
              }}
            >
              {active ? (
                <>
                  <img src={Ativo} />
                  <p
                    style={{
                      color: "#0CBA90",
                      marginBottom: "0",
                      paddingLeft: "7px",
                      fontFamily: "Source Sans Pro",
                    }}
                  >
                    <span style={{ color: "#0CBA90" }}>ATIVO</span>
                  </p>
                </>
              ) : (
                <>
                  <img src={Suspenso} />
                  <p
                    style={{
                      color: "#0CBA90",
                      marginBottom: "0",
                      paddingLeft: "7px",
                      fontFamily: "Source Sans Pro",
                    }}
                  >
                    <span style={{ color: "#D70F57" }}>SUSPENSO</span>
                  </p>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={16}>
            <hr style={{ marginTop: "0" }}></hr>
          </Grid>

          {subscriptionPlanType != SubscriptionPlanType.FREE ? (
            <Grid item xs={16}>
              <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                <b style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                  Você tem o acesso aos recursos do Portal desde
                </b>{" "}
                {moment(initialDate).format("DD/MM/YYYY")}
                <br />
                <br />
                {active && (
                  <span>
                    Acesse até {moment(expirationDate).format("DD/MM/YYYY")}{" "}
                  </span>
                )}
              </p>
              {paymentLink && orderPlan}
              {recurrenceActive && (
                <span>
                  <Button
                    type="submit"
                    sx={{
                      marginTop: "20px",
                      bgcolor: "#E74C3C",
                      color: "#fff",
                      textTransform: "none !important",
                      width: "90%",
                      fontFamily: "Source Sans Pro",
                      "&:hover": {
                        bgColor: "#fff",
                        color: "#E74C3C",
                        border: "1px solid #E74C3C",
                      },
                    }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Cancelar Assinatura
                  </Button>
                </span>
              )}
            </Grid>
          ) : (
            <Grid item xs={16}>
              <p style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                <b style={{ fontFamily: "Source Sans Pro", color: "#7B7B7B" }}>
                  Você tem o acesso aos recursos do Portal desde
                </b>{" "}
                {moment(initialDate).format("DD/MM/YYYY")}
                <br />
                <br />
                <b style={{ fontFamily: "Source Sans Pro" }}>
                  Assinatura temporária gratuita por 1 mês
                </b>
                <br />
                Acesse até {moment(expirationDate).format("DD/MM/YYYY")}
              </p>
            </Grid>
          )}
          {!active && (
            <Grid item xs={16}>
              <p style={{ fontFamily: "Source Sans Pro" }}>
                A sua assinatura está suspensa. Escolha uma das opções abaixo e
                volte a utilizar os recursos do Portal do MEI. Em caso de
                dúvidas, entre em contato:
                <b style={{ fontFamily: "Source Sans Pro" }}>
                  <a href="mailto:portaldomei@acsp.com.br">
                    portaldomei@acsp.com.br
                  </a>
                </b>
                <br></br>
              </p>
            </Grid>
          )}

          {!recurrenceActive && !paymentLink && avisoExpiracao}
          {showPlans && !recurrenceActive ? (
            <form onSubmit={formik.handleSubmit}>
              {!paymentLink ? (
                <Grid item xs={16}>
                  <br></br>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <p
                        style={{
                          marginBottom: "0",
                          fontFamily: "Source Sans Pro",
                          color: "#7B7B7B",
                          display: alteracaoCartao ? "none" : "",
                        }}
                      >
                        Escolha do plano:
                      </p>
                    </FormLabel>
                    {alteracaoCartao && (
                      <>
                        <b style={{ fontFamily: "Source Sans Pro" }}>
                          Ocorreu um erro na ultima cobrança da fatura, favor
                          selecione o plano de recorrencia e insira um novo
                          cartão
                        </b>
                      </>
                    )}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Mensal"
                      onChange={formik.handleChange}
                      name="radioPlans"
                      value={formik.values.inCompliance}
                    >
                      {subscriptionPlanData.map((data, key) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={key}
                          data-name = {data.name}
                          style = {{display:data.name == "Avulso Mensal" ? "none" : "flex"}}
                        >
                          <FormControlLabel
                            value={data.id}
                            
                            control={
                              <Radio
                                required={true}
                                sx={{
                                  color: "#7B7B7B",
                                  "&.Mui-checked": {
                                    color: "#58C5C6",
                                  },
                                }}
                              />
                            }
                            sx={{
                              marginBottom: "0",
                            }}
                            style={{
                              display:
                                alteracaoCartao && !data.recorrencia
                                  ? "none"
                                  : "",
                            }}
                            onChange={() => {
                              formik.setFieldValue("cardNumber", "");
                              formik.setFieldValue("cardName", "");
                              formik.setFieldValue("expiryDate", "");
                              formik.setFieldValue("cvv", "");
                              formik.setErrors("cardNumber", "");
                              formik.setErrors("cardName", "");
                              formik.setErrors("expiryDate", "");
                              formik.setErrors("cvv", "");
                              setSubscriptionPlanId(data.id);
                              setValuePlan(data.price);
                              setCartaoRecorrencia(data.recorrencia);
                              if (data.recorrencia) {
                                setShowCardForm(true);
                              } else {
                                setShowCardForm(false);
                              }
                            }}
                          />
                          <p
                            style={{
                              marginBottom: "0",
                              marginLeft: "-15px",
                              fontFamily: "Source Sans Pro",
                              display:
                                alteracaoCartao && !data.recorrencia
                                  ? "none"
                                  : "",
                            }}
                          >
                            {data.name} /{" "}
                            <b style={{ fontFamily: "Source Sans Pro" }} >
                              R$ {data.price.replace(".", ",")}
                            </b>
                            {data.name === "Anual" ? (
                              <span
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#0CBA90",
                                }}
                              >
                                {" "}
                                (Você economiza R$ 20,28)
                              </span>
                            ) : (
                              <></>
                            )}
                          </p>
                        </Box>
                      ))}
                    </RadioGroup>

                    {showCardForm && (
                      <div>
                        <b style={{ fontFamily: "Source Sans Pro" }}>
                          Atenção, ao inserir os dados do cartão, ele ficará
                          salvo e todo mês será realizado a cobrança no cartão
                          de modo automático
                        </b>
                        <div className="row">
                          <div className="col-md-12 mt-4">
                            <div className="form-group">
                              <InputMask
                                mask="9999 9999 9999 9999"
                                maskChar=""
                                type="text"
                                className="form-control"
                                placeholder="Número do cartão"
                                value={formik.values.cardNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="cardNumber"
                              />
                              {formik.touched.cardNumber &&
                              formik.errors.cardNumber ? (
                                <div className="text-danger">
                                  {formik.errors.cardNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nome do cliente"
                                value={formik.values.cardName}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "cardName",
                                    e.target.value.toUpperCase()
                                  )
                                }
                                onBlur={formik.handleBlur}
                                name="cardName"
                              />
                              {formik.touched.cardName &&
                              formik.errors.cardName ? (
                                <div className="text-danger">
                                  {formik.errors.cardName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <InputMask
                                mask="99/99"
                                maskChar=""
                                type="text"
                                className="form-control"
                                placeholder="Data de validade"
                                value={formik.values.expiryDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="expiryDate"
                              />
                              {formik.touched.expiryDate &&
                              formik.errors.expiryDate ? (
                                <div className="text-danger">
                                  {formik.errors.expiryDate}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <InputMask
                                mask="9999"
                                maskChar=""
                                type="text"
                                className="form-control"
                                placeholder="CVV"
                                value={formik.values.cvv}
                                onChange={formik.handleChange}
                                name="cvv"
                              />
                              {formik.touched.cvv && formik.errors.cvv ? (
                                <div className="text-danger">
                                  {formik.errors.cvv}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <FormControlLabel
                      onChange={(e) =>
                        formik.setFieldValue(
                          "createAcceptTerms",
                          e.target.checked
                        )
                      }
                      checked={formik.values.createAcceptTerms}
                      control={<Checkbox name="createAcceptTerms" />}
                      value={formik.values.createAcceptTerms}
                      label={
                        <div>
                          <font color="#002C43">Eu li e concordo com o</font>{" "}
                          <a onClick={() => openModalTermsPop(true)}>
                            {" "}
                            <font color="#58C5C6">Termo de Adesão</font>
                          </a>
                        </div>
                      }
                    />
                  </FormControl>
                  <span>
                    <span>
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: formik.values.createAcceptTerms
                            ? "#58C5C6"
                            : "#7B7B7B",
                        }}
                        sx={{
                          marginTop: "20px",
                          bgcolor: "#58C5C6",
                          color: "#fff",
                          textTransform: "none !important",
                          width: "90%",
                          fontFamily: "Source Sans Pro",
                          "&:hover": {
                            bgColor: "#fff",
                            color: "#7B7B7B",
                            border: "1px solid #58C5C6",
                          },
                        }}
                      >
                        {/* Assinar */}
                        {alteracaoCartao
                          ? "Alteração de cartão da recorrencia atual"
                          : "Solicitar pagamento"}
                      </Button>
                    </span>
                  </span>
                </Grid>
              ) : (
                <Grid item xs={16}>
                  <hr style={{ marginTop: "0" }}></hr>
                  <p
                    style={{
                      marginBottom: "0",
                      fontFamily: "Source Sans Pro",
                      fontSize: "18px",
                    }}
                  >
                    Status do pagamento:
                  </p>
                  <br />
                  {awaitPayment ? (<>
                    <p style={{ fontFamily: "Source Sans Pro" }}>
                    <b style={{ fontFamily: "Source Sans Pro" }}>
                      Pendente de pagamento 
                      <br></br>
                      Caso o pagamento já foi realizado, favor recarregar a página
                    </b>
                  </p>
                  </>) : (<>
                  <p style={{ fontFamily: "Source Sans Pro" }}>
                    <b style={{ fontFamily: "Source Sans Pro" }}>
                      Pendente de pagamento
                    </b>
                  </p>
                  <p style={{ fontFamily: "Source Sans Pro" }}>
                    Link para pagamento{" "}
                    <a href={paymentLink} target="_blank" rel="noreferrer">
                      {paymentLink}
                    </a>
                  </p>
                  </>)}
                </Grid>
              )}

              {alteracaoCartao && (
                <span>
                  <Button
                    type="button"
                    sx={{
                      marginTop: "20px",
                      bgcolor: "#E74C3C",
                      color: "#fff",
                      textTransform: "none !important",
                      width: "90%",
                      fontFamily: "Source Sans Pro",
                      "&:hover": {
                        bgColor: "#fff",
                        color: "#E74C3C",
                        border: "1px solid #E74C3C",
                      },
                    }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Cancelar Assinatura
                  </Button>
                </span>
              )}
            </form>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBackdrop}
      >
        <CircularProgress color="inherit" />{" "}
      </Backdrop>
    </>
  );
}
