import React, { useEffect } from 'react';
import { useLocation, useHistory  } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const Middleware = () => {
    const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem("jwt_auth")) {
        const token = localStorage.getItem("jwt_auth");
        const decodedToken = jwt_decode(token);

        // console.log('associated', decodedToken.cnpj);
        // console.log('planoAssinatura', planoAssinatura);
        // console.log('Navegou para:', location.pathname);
        if(decodedToken.active == false && decodedToken.pagamento == 1) {
            if (location.pathname !== '/profileMenu') {
                history.push('/profileMenu');
            }
        }
    }
  }, [location, history]); // O hook será chamado sempre que o `location` mudar

  return null; // Este componente não precisa renderizar nada
};

export default Middleware;
